import "./contact.scss";
import { useEffect, useState } from "react";

const Contact = () => {
	const [captcha, setCaptcha] = useState('');
    const [values, setValues] = useState({ 
        username: '', email: '', phone: '', desc: '', captcha: ''
    });
	const set = (name: string) => {
        return ({ target: { value } }: any) => {
          setValues(oldValues => ({...oldValues, [name]: value }));
        }
    };
	useEffect(() => {
        createCaptcha(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
	function createCaptcha(length: number, chars: string | any[]) {
        let result = '';
        for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        setCaptcha(result);
    }
	function handleClick(event: any) {
        if(values.captcha === captcha) {
            return true;
        } else {
            event.preventDefault();
            alert('Please Enter Valid Captcha');
            return false;
        }
    }
	return (
		<>
			<section className="contact_" id="contact">
				<h2 className="main_heading">Contact US</h2>
			</section>
			<div className="container_">
				<div className="card_img">
					<img src="/assets/old_paper.png" alt="paper" />
				</div>
				<div className="wrapper_">
					<form onSubmit={handleClick} method="post" className="form" action="https://lucifersgold.com/mail/send.php"  id="contactusform" encType= "multipart/form-data">
					<input type="text" value={values.username} onChange={set('username')}
                                        name="username" placeholder="Name" required />
					<input type="email" placeholder="Email" value={values.email}
                                        name="email" onChange={set('email')} required />
					<input maxLength={10} onChange={set('phone')} required type={"tel"} placeholder="Phone" value={values.phone}
                                        name="phone" />
					<textarea onChange={set('desc')} placeholder="Description" rows={4} value={values.desc}
                                        name="desc"></textarea>
					<div className=" form-group captcha-wrapper">
						<div className="captcha">{captcha}</div>
						<input required value={values.captcha} onChange={set('captcha')} type={"input"} placeholder="Enter the text"
						name="captcha" />
					</div>
					<button type="submit" name="signUp">Submit</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default Contact;
