import "./theOrigin.scss";
import { Link } from "react-router-dom";
import { ParallaxBanner } from "react-scroll-parallax";

interface props {
	loading: boolean;
}

const TheOrigin = ({ loading }: props) => {
	return (
		<ParallaxBanner
			layers={[{ image: "./assets/imggg.png", speed: -10 }]}
			className="aspect-[2/1]"
		>
			<section className="origin" id="home">
				<nav>
					<Link to="/">
						<img src="/assets/nav_logo.png" alt="nav_logo" />
					</Link>
					<ul>
						<li>
							<a href="#home">Home</a>
						</li>
						<li>
							<a href="#about">About Us</a>
						</li>
						<li>
							<a href="#contact">Contact Us</a>
						</li>
					</ul>
				</nav>
				<div className="des">
					<div className="para">
						<h3 className="main_heading">The Origin </h3>
						<p>
							The origin of our ‘gold’ lends itself to rather unusual
							circumstances. Picture the scene of a non-descript cocktail bar in
							an old Italian city. Two drinks enthusiasts; one passionate
							British whisky drinker and his ardent bourbon drinking American
							friend, and a rather tense game of Texas Holdem. As the gambling
							continued the drinks flowed, the British gent only ordering scotch
							and the American diplomat only ordering bourbon. While enjoying
							their respective drinks they both noticed their nectar was
							different, unusual, better….but too engrossed in their game to
							comment. At the end of the evening both our gentlemen challenged
							the bar- tender about their drinks. The bartender replied
							‘Gentlemen, do forgive me. You were both so engrossed in your game
							I decided to be mischievous and for both your drinks I poured one
							part whisky and one part bourbon’. Both men toasted ‘Lucas’ the
							bartender and congratulated him on his special blend and pledged
							to return for more. The very next day our two friends returned to
							the same bar to continue their gamble and for more of their
							special liquid. They asked for Lucas, the bartender of the night
							before. They were as- tounded to be told that there was no such
							bartender of that name, nor anyone that fitted his description
							currently
						</p>
						<img src="/assets/old_paper.png" alt="old_paper" />
					</div>
				</div>
			</section>
		</ParallaxBanner>
	);
};

export default TheOrigin;
