import "./about.scss";
import { ParallaxBanner } from "react-scroll-parallax";

const About = () => {
	return (
		<ParallaxBanner
			layers={[{ image: "./assets/about_bg.png", speed: -30 }]}
			className="aspect-[2/1]"
		>
			<section className="about" id="about">
				<div className="about_img">
					<img src="/assets/about_primary.jpg" alt="about_main_img" />
				</div>
				<div className="about_content">
					<h2 className="main_heading">Lucifer’s gold</h2>
					<p>
						Lucifer’s gold is a blend of grain scotch whisky and Kentucky
						bourbon, showing the very best of each liquid and combining them to
						make something intriguing. It has all the fruitness and spice that
						one expects from scotch, balanced with vanilla and charred oak notes
						from bourbon, to deliver a beautiful balance of sweetness, richness,
						depth and smoothness. <br /> <br /> Lucifer’s Gold gives you permission to mix.
					</p>
				</div>
			</section>
		</ParallaxBanner>
	);
};

export default About;
