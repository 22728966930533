import "./services.scss";
import { ParallaxBanner } from "react-scroll-parallax";

const Services = () => {
	return (
		<ParallaxBanner
			layers={[{ image: "/assets/smokes.png", speed: -25 }]}
			className="aspect-[2/1]"
		>
			<section className="services">
				<h2 className="main_heading">Permission to mix</h2>
				<p className="para_heading">
					Lucifer’s gold is for the non-confirmist,the non-traditionalist.Try it
 					as a coocktail or a shot
				</p>
				<div className="service_wrapper">
					<div className="service_content">
						<div>
							<h2 className="sub_heading">666 shot</h2>
							<p className="sub_para">
								Pour 25ml of Lucifer’s gold into a shot glass and add 6 drops of
								tobasco(if you dare...)
							</p>
						</div>
						<img src="/assets/old_paper.png" alt="oldPaper" />
					</div>
					<div className="img">
						<img src="/assets/glass/glass_1.png" alt="services_glass" />
					</div>
				</div>
				<div className="service_wrapper">
					<div className="img">
						<img
							className="image_2"
							src="/assets/glass/glass_2.png"
							alt="services_glass"
						/>
					</div>
					<div className="service_content">
						<img src="/assets/old_paper.png" alt="oldPaper" />
						<div>
							<h2 className="sub_heading">devils pitch fork</h2>
							<p className="middle_text">(LONG DRINK-UK)</p>
							<p className="sub_para">
								Drop a wedge of lime and few mint leaves into a highball glass
								and fill with cubed ice.Add 50ml Lucifer’s gold,top with ginger beer
								and add a few drops of Tabasco for a delvish kick
							</p>
						</div>
					</div>
				</div>
				<div className="service_wrapper">
					<div className="service_content">
						<img src="/assets/old_paper.png" alt="oldPaper" />
						<div>
							<h2 className="sub_heading">Devil’s sunset</h2>
							<p className="middle_text">(LONG DRINK-INTERNATIONAL)</p>
							<p className="sub_para">
								Fill a Collins glass with cubed ice,add 50ml Lucifer’s Gold and
								top with Lemonade and garnish with a wheel orange.
							</p>
						</div>
					</div>
					<div className="img">
						<img
							className="img_3"
							src="/assets/glass/glass_3.png"
							alt="services_glass"
						/>
					</div>
				</div>
			</section>
		</ParallaxBanner>
	);
};

export default Services;
