import { useEffect, useState } from "react";
import "./ageConcern.scss";
import Old_paper from './assets/burning_paper.png';
import Modal from 'react-bootstrap/Modal';

interface props {
	setPlayVideo: boolean;
	tempVal: boolean;
	setTempVal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AgeConcern = ({ setTempVal, tempVal, setPlayVideo }: props) => {
	const [age, setAge] = useState("");
	const [msg, setMsg] = useState(false);
	const [success, setSuccess] = useState(false);
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const status = params.get('status')
		if(status) {
			if(Number(status) === 200) {
				setSuccess(true);
			} else {
				setSuccess(false);
			}
		}
	},[]);
	function hideModal() {
		const url = window.location.href;
		const newUrl = removeQueryParams(url);
		window.location.href = newUrl
		setSuccess(false)
	}
	function removeQueryParams(url: any) {
		return url.split("?")[0];
	}

	const setBtn = () => {
		let diff_ms = new Date().getFullYear();
		let current = Number(age);
		if (age.length === 4 && diff_ms - current >= 18) {
			setTempVal(true);
			setMsg(false);
			// @ts-ignore
			setPlayVideo(true)
		}else{
			setAge("");
			setMsg(true);
			setTimeout(() =>{
				setMsg(false);
			},2000);
		}
	};

	return (
		<>
			<div className={tempVal ? "dis_none" : "container"}>
				<div className="wrapper">
					<h1 className="heading">Welcome to Lucifer</h1>
					<p>Are You Old Enough To Speak To Him ?</p>
					<input
						type="text"
						placeholder="YYYY"
						name="age"
						value={age}
						onChange={(e) => setAge(e.target.value)}
					/>
					<button onClick={setBtn}>Enter The Site</button>
					{
						msg && <p className="not_old_enough">Unfortunately you are not old enough yet</p>
					}
					<p>You Have To Be 18 Years To Enter The Site</p>
					<img src={Old_paper} alt="old_paper_images" />
				</div>
			</div>
			<Modal show={success} fullscreen={'md-down'} centered={true} onHide={() => hideModal()}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>Thank you for contacting us. We will get back to you soon.</Modal.Body>
			</Modal>
		</>		
	);
};

export default AgeConcern;
