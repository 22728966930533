import TheOrigin from "../components/TheOrigin/TheOrigin";
import {useEffect, useState} from "react";
import About from "../components/About/About";
import Services from "../components/Services/Services";
import Contact from "../components/Contact/Contact";
import Video from "../components/Video/Video";
import AgeConcern from "../components/AgeConcern/AgeConcern";



const Home = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tempVal, setTempVal] = useState<boolean>(false);
  const [playVideo, setPlayVideo]: any = useState(false);

  return (
    <div className={tempVal ? "posi_relative" : "posi_fixed"}>
      <Video setPlayVideo={setPlayVideo} playVideo={playVideo} />
      {/* <Hero loading={loading}/> */}
      <TheOrigin loading={loading}/>
      <About/>
      <Services/>
      <Contact/>
      <AgeConcern setPlayVideo={setPlayVideo} tempVal={tempVal} setTempVal={setTempVal}/>
    </div>
  );
};

export default Home;
