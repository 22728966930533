import React, { useState, useRef } from "react";
import "./video.scss";

interface props {
	setPlayVideo: boolean;
	playVideo: React.Dispatch<React.SetStateAction<boolean>>;
}

const Video = ({ setPlayVideo, playVideo }: props) => {
	const [play, setPlay] = useState(false);
	const [startVideo, setStartVideo] = useState(false);
	const videoPlayer = useRef(null);
	function myCallback() {
		setPlay(true);
		// console.log("stopped video");
	}
	function playing() {
		// console.log("playing video");
	}
	// @ts-ignore
	if (playVideo) {
		// @ts-ignore
		videoPlayer.current.play();
	}

	return (
		<div
			className={play ? "video_container bg_block" : "video_container bg_dark"}
		>
			<video
				className={play ? "videoNone" : "videoDisplay"}
				height="120%"
				width="130%"
				onEnded={() => myCallback()}
				onPlaying={() => playing()}
				muted
				ref={videoPlayer}
			>
				<source src="/assets/video_1.mp4" type="video/mp4" />
			</video>
		</div>
	);
};

export default Video;