import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import Home from "./Pages/Home";

function App() {
	return (
		<ParallaxProvider>
			<Router>
				<Routes>
					<Route path="/" element={<Home />} />
				</Routes>
			</Router>
		</ParallaxProvider>
	);
}

export default App;
